// Main entry point for member javascript

import { Turbo } from '@hotwired/turbo-rails';
import { stimulus } from 'init/init'; // common imports and app initialization
import { definitionsFromContext } from 'init/stimulus/stimulus';

import { injectVisit } from 'lib/visit';

// Automatically import all Stimulus controllers except under admin
stimulus.load(
  definitionsFromContext(
    require.context('../components', true, /^(?!.*admin\/).*_controller\.js$/),
  ),
);
stimulus.load(
  definitionsFromContext(
    require.context('../pages', true, /^(?!.*admin\/).*_controller\.js$/),
  ),
);

// Turbo configuration

// Clear cache when Rails UJS ajax events occur since they frequently change
// server state
$(document).on('ajax:before', '[data-remote]', () => {
  Turbo.clearCache();
});

// Configure the visit method to use Turbo.visit.
injectVisit(Turbo.visit);

// Disable Turbo Drive on all forms by default.
//
// Unlike Turbolinks, Turbo Drive requires all forms that use render rather than
// redirect to return a status of 422. Our app doesn't do so, and finding and
// updating all relevant forms would be error prone.
//
// https://github.com/hotwired/turbo/pull/419
// https://stackoverflow.com/questions/70921317/how-can-i-disable-hotwire-turbo-the-turbolinks-replacement-for-all-forms-in
Turbo.config.forms.mode = 'optin';
